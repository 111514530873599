export const FEATURE_FLAGS: Record<string, string> = {
  APPLE_LOGIN: 'feat__apple_login',
  HOME_PAGE: 'feat__home_page',
  ABOUT_PAGE: 'feat__about_page',
  ACCOUNT: 'feat__account',
  LIBRARY: 'feat__library',
  PROFILE: 'feat__profile'
}

export default FEATURE_FLAGS
